import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
// import 'tailwindcss/tailwind.css';
import { BrowserRouter, Route, Routes, json } from "react-router-dom";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";

// import NavBar from './components/NavBar';
import Home from "./components/tailwindComponents/Home";
import ErrorPage from "./components/ErrorPage";
import "./components/css/style.css";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import NavBar from "./components/tailwindComponents/NavBar";
import UserLibrary from "./components/tailwindComponents/TailUserLibrary";

import 'spiketip-tooltip/spiketip.min.css'

import CustomisedTemplate from "./components/tailwindComponents/CustomisedTemplate";
import TermOfServices from "./components/tailwindComponents/TermOfServices"
import PrivacyPolicy from "./components/tailwindComponents/PrivacyPolicy";
import ContactUs from "./components/tailwindComponents/ContactUS";

import LandingPage from "./components/tailwindComponents/LandingPage"

import Pricing from "./components/tailwindComponents/Pricing";
import LandingTest from "./components/tailwindComponents/LandingTest";
import { useEffect } from "react";
import UserService from "./services/UserService";
import { useDispatch } from "react-redux";
import { getCheckPricing } from "./Redux/Action/action";
import axios from "axios";
import PaymentSuccess from "./components/tailwindComponents/PaymentSuccess";
import PaymentFailed from "./components/tailwindComponents/PaymentFailed";
import MySubscriptionDetails from "./components/tailwindComponents/MySubscriptionDetails";
import BailApplication from "./components/tailwindComponents/BailApplication";
import AnticipatoryBailApplication from "./components/tailwindComponents/AnticipatoryBailApplication";
import OfflineError from "./components/tailwindComponents/OfflineError";
import FooterLanding from "./components/tailwindComponents/FooterLanding";



const { REACT_APP_CREATE_AGREEMENT_API } = process.env
function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    if (UserService.isLoggedIn()) setUserStatus()
  }, []);
  const checkTimeOf1Hour = (value) => {
    const dateTimeString = value;
    const dateTime = new Date(dateTimeString);
    const targetDateTime = new Date(dateTime.getTime() + 60 * 60 * 1000); // Adding 1 hour in milliseconds
    const currentDateTime = new Date();
    if (currentDateTime >= targetDateTime) return 'over'
    else return 'not-over'

  }
  const setUserStatus = () => {
    const userID = UserService.getUserId()
    const userStatusObjFromStorage = JSON.parse(localStorage.getItem(userID + "StatusKeySubscriptions"))


    if (userStatusObjFromStorage) {
      if (userStatusObjFromStorage.navbarCheck.current) dispatch(getCheckPricing(false))
      else dispatch(getCheckPricing(true))
      if (checkTimeOf1Hour(userStatusObjFromStorage.userStatusTime) === "over") {
        localStorage.removeItem(userID + "StatusKeySubscriptions");
        getListOfUserSubscription()
      }
    }
    else getListOfUserSubscription() //for frist time
  }
  const getListOfUserSubscription = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("keycloak_user_id", UserService.getUserId());
        // sendData.append("keycloak_user_id","ddc47779-e929-491f-9fbe-3b5cc4d30df6");

        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/list_active_user_subscriptions`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access_token": UserService.getToken()
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        if (getResponse.status === 200) {
          // console.log(getResponse);
          const isCurrentAvailabe = (value) => {
            let temp;
            value.forEach((ele) => {
              if (ele.current_status === "active") temp = true
            })
            if (temp === true) {
              dispatch(getCheckPricing(false))
              return true
            }
            else {
              dispatch(getCheckPricing(true))
              return false
            }
          }
          const time = new Date()
          const userID = UserService.getUserId()
          const tempObj = {
            userStatusTime: time,
            navbarCheck: {
              current: isCurrentAvailabe(getResponse.data)
            }
          }
          localStorage.setItem(userID + "StatusKeySubscriptions", JSON.stringify(tempObj))
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
  }

  return (
    <BrowserRouter>
      <RenderOnAnonymous>
        <ReactNotifications />
        <NavBar />
        <Routes>
          <Route path="/" element={<LandingTest />} />
          {/* <Route path="/" element={<LandingPage />} /> */}
          {/* <Route path="/" element={<SignUp />} /> */}
          <Route path="/app/contact-us" element={<ContactUs />} />
          <Route path="/terms-of-service" element={<TermOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing />} />
          {/* <Route path="/csv" element={<CSV />} /> */}
          <Route path="/offline-error" element={<OfflineError />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <FooterLanding />
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <NavBar />
        <ReactNotifications />
        <Routes>
          <Route path="/terms-of-service" element={<TermOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/" element={<LandingTest />} />
          <Route path="/app" element={<Home />} />
          <Route path="/app/contact-us" element={<ContactUs />} />
          <Route path="/app/customised-template" element={<CustomisedTemplate />} />

          <Route path="/app/library" element={<UserLibrary />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/app/payment_success" element={<PaymentSuccess />} />
          <Route path="/app/payment_failed" element={<PaymentFailed />} />
          <Route path="/app/my-subscriptions" element={<MySubscriptionDetails />} />
          <Route path="/offline-error" element={<OfflineError />} />




          <Route path="/app/bail-application" element={<BailApplication />} />
          <Route path="/app/anticipatory-bail-application" element={<AnticipatoryBailApplication />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <FooterLanding />
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;
