import React, { useEffect, useState } from 'react'
import jQuery from 'jquery'
import UserService from "../../services/UserService"
import axios from 'axios'
import Loader from "react-js-loader"
import { ProgressBar } from "react-loader-spinner"
import { useDispatch, useSelector } from 'react-redux'
import { getNotification, getUserDetails } from '../../Redux/Action/action'
import { useNavigate } from 'react-router'
import "../css/home.css"
import "../css/subscription.css"
import FooterLanding from './FooterLanding'
import subscription1 from "../.././assets/subscription1.jpg"
import subscription2 from "../.././assets/subscription2.jpg"
import Popup from './Popup'
import VideoPopup from './VideoPopup'
import vdo from '../../assets/Demo1.mp4'
import "../css/popup.css"
import pricing from "../../assets/pricing.png"

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env
const Pricing = ({ onConfirm, onCancel, text, proceed }) => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(false)
    const [subscriptionData, setSubscriptionData] = useState([])
    const [loader, setLoader] = useState(false)
    const [warning, setWarning] = useState(false)
    const [currencyCode, setCurrencyCode] = useState("inr")
    const [monthly, setMonthly] = useState(true)
    const [currency, setCurrency] = useState(false)
    const [active, setActive] = useState("")
    const [validity, setValidity] = useState("")

    useEffect(() => {
        getSubscription()
        if (UserService.isLoggedIn()) getPlanDetails()
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleConfirm = () => {
        setShowPopup(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onCancel();
    };

    const getPlanDetails = async (value) => {
        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "access_token": UserService.getToken()
            };
            const getResponse = await axios.post(
                `${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
                null,
                { headers: headers }
            );
            if (getResponse.status === 200) {
                dispatch(getUserDetails(getResponse.data));
                if (Object.keys(getResponse.data.user_subscription_summary).length) {
                    const name = Object.entries(getResponse.data.user_subscription_summary)[0][1].plan.nickname
                    const valid = (Object.entries(getResponse.data.user_subscription_summary)[0][1].plan.amount_decimal) / 100
                    setActive(name)
                    setValidity(valid)
                }
                else {
                    setActive("Free")
                }
            }
        } catch (err) {
            console.log("Error", err);
            if (err.response.status === 400) {
                dispatch(getUserDetails(err.response.data.detail));

                setActive("Free")

                // dispatch(
                //   getNotification({
                //     message:
                //       "You have run out of credits!! Please subscribe and get more credits.",
                //     type: "info",
                //   })
                // );
            } else if (err.response && err.response.status === 401) {
                dispatch(
                    getNotification({
                        message: "Session expired! Please log in again",
                        type: "default",
                    })
                );
                setTimeout(() => {
                    UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/pricing` });
                }, 3000);
            } else {
                dispatch(
                    getNotification({
                        message: "You have encountered an error. Please try after some time",
                        type: "default",
                    })
                );
                navigate("/offline-error")
            }
        }
        // setLoader(false)
    };


    const getSubscription = async () => {
        // if (UserService.isLoggedIn()) {
        if (true) {
            const config = {
                method: "get",
                url: `${REACT_APP_CREATE_AGREEMENT_API}/get_subscriptions`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                },
            }
            try {
                setLoader(true)
                const getResponse = await axios(config)
                if (getResponse.status === 200) {
                    setLoader(false)
                    // const data = getResponse.data
                    // const elementToMove = data[5]

                    // // Find the index of the element to move
                    // const indexToMove = data.findIndex(item => item.subscription_id === elementToMove.subscription_id);

                    // // If the element exists in the array
                    // if (indexToMove !== -1) {
                    //     // Remove the element from the array
                    //     const removedElement = data.splice(indexToMove, 1)[0];

                    //     // Append the removed element back to the array
                    //     data.push(removedElement);
                    // }
                    setSubscriptionData([...getResponse.data])
                }
            }
            catch (err) {
                setLoader(false)
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Please log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/pricing` });
                    }, 3000);
                } else {
                    dispatch(
                        getNotification({
                            message: "Network issue. Please refresh the page or try again aftersometime.",
                            type: "default",
                        })
                    );
                    navigate("/offline-error")
                }
            }
        }
        setLoader(false)
    }


    const paymentPage = async (currCode, ID) => {
        setLoader(true)
        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "access_token": UserService.getToken()
            };
            const getResp = await axios.post(`${REACT_APP_CREATE_AGREEMENT_API}/create_checkout_session_for_subscription_id?keycloak_user_id=${UserService.getUserId()}&subscription_id=${ID}&currency_code=${currCode}&stripe_mode=${"subscription"}&stripe_email_id=${UserService.getEmail()}`,
                null,
                { headers: headers })
            if (getResp.status === 200) {
                const data = { session: getResp.data.session_id, subscriptionId: ID }
                // console.log(getResp.data);
                sessionStorage.setItem("data", JSON.stringify({ ...data }))
                // console.log(sessionStorage.getItem("data"));
                const payemntUrl = getResp.data.url
                setLoader(false)
                setWarning(true)
                setTimeout(() => {
                    window.location = payemntUrl
                }, 4000)
            }
        }
        catch (err) {
            console.error(err)
            if (err.response.status = 401) {
                dispatch(getNotification({
                    message: "Session expired! Please log in again",
                    type: "default"
                }))
                setTimeout(() => {
                    UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/pricing` })
                }, 3000)
            }
        }
        setLoader(false)
    }


    if (subscriptionData.length) {
        return (
            <div className="row mt-2">

                {
                    subscriptionData && subscriptionData.map((ele, ind) => {
                        return (
                            <>
                                {
                                    true && (
                                        <div className="popup-overlay alert alert-secondary" role="alert">

                                            <div className="p-2 position-relative">
                                                {
                                                    loader &&
                                                    <div style={{ marginTop: "30vh", width: "25vw" }} className='md:col-span-2 md:mt-0' >
                                                        <Loader type="bubble-spin" bgColor={"#000000"} title={"Loading..."} color={'#000000'} size={100} />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </div >
                                                }
                                                {
                                                    warning &&
                                                    <div className="containe" style={{ marginTop: "30vh" }}>
                                                        <p className='text-center fw-bolder'>Please do not refresh, close or navigate away from this page until the transaction is complete to ensure that your payment is processed successfully.
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <ProgressBar
                                                                    height="150"
                                                                    width="5000"
                                                                    ariaLabel="progress-bar-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperclassName="progress-bar-wrapper"
                                                                    borderColor='#F4442E'
                                                                    barColor='#51E5FF'
                                                                />
                                                            </div>
                                                        </p>
                                                    </div>

                                                }

                                                {/* <div class={loader || warning ? "d-none" : "p-2 w-full"}>
                                                    <div class="h-full p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                                                        <h1 class="text-3xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                                                            INR {ele.price}/year
                                                        </h1>
                                                        
                                                        <p class="flex items-center text-gray-600 mb-2">
                                                        <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                                </svg>
                                                            </span>30 minute training session on AI workflows for advocates.
                                                            </p>
                                                            <p class="flex items-center text-gray-600 mb-2">
                                                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                            <path d="M20 6L9 17l-5-5"></path>
                                                            </svg>
                                                            </span>Unlimited downloads.
                                                            </p>
                                                            <p class="flex items-center text-gray-600 mb-2">
                                                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                </svg>
                                                            </span>Access to our AI advocates community.
                                                        </p>
                                                        <p class="flex items-center text-gray-600 mb-2">
                                                            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                            <path d="M20 6L9 17l-5-5"></path>
                                                            </svg>
                                                            </span>Email support.
                                                            </p>
                                                            <button
                                                            onClick={() => {
                                                                paymentPage(ele.currency_code, ele.subscription_id)
                                                                }}
                                                                class="flex items-center mt-3 text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded">Subscribe now
                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                                </svg>
                                                        </button>
                                                    </div>
                                                </div> */}
                                                <div className='card get-shadow relative' style={{ width: "40rem", }}>
                                                    <i onClick={handleCancel} style={{ zIndex: "9999999" }} className='far fa-window-close fa-2x position-absolute -top-0 -right-0 cursor-pointer'></i>
                                                    <div className='row p-2'>
                                                        <div className="col-sm-6 absolute">
                                                            <img src={pricing} alt="pricing" className='w-full h-full' />
                                                            {/* <h1 className='absolute top-12'>Professional</h1> */}
                                                            {/* <h1>Professional</h1> */}
                                                            {/* <span>Anual Plan</span> */}
                                                        </div>
                                                        <div className="col-sm-6 py-3">
                                                            <p class="flex items-center text-gray-600 mb-2">
                                                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                                        <path d="M20 6L9 17l-5-5"></path>
                                                                    </svg>
                                                                </span>30 minute training session on AI workflows for advocates.
                                                            </p>
                                                            <p class="flex items-center text-gray-600 mb-2">
                                                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                                        <path d="M20 6L9 17l-5-5"></path>
                                                                    </svg>
                                                                </span>Unlimited downloads.
                                                            </p>
                                                            <p class="flex items-center text-gray-600 mb-2">
                                                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                                        <path d="M20 6L9 17l-5-5"></path>
                                                                    </svg>
                                                                </span>Access to our AI advocates community.
                                                            </p>
                                                            <p class="flex items-center text-gray-600 mb-2">
                                                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                                        <path d="M20 6L9 17l-5-5"></path>
                                                                    </svg>
                                                                </span>Email support.
                                                            </p>
                                                            <div className="h-full border-t-4 pt-2">
                                                                <h1 className="text-2xl text-gray-900 leading-none mb-0">
                                                                    ₹ INR {ele.price}/year
                                                                </h1>
                                                                <button
                                                                    onClick={() => {
                                                                        paymentPage(ele.currency_code, ele.subscription_id);
                                                                    }}
                                                                    className="flex items-center mt-3 -ml-2 text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded offset">
                                                                    Subscribe now
                                                                    <svg
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        className="w-4 h-4 ml-auto"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    })
                }
            </div >
        )
    }
}

export default Pricing