import React from "react";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router";
import pricingLanding1 from "../../assets/pricing-landing.jpeg"
import pricingLanding2 from "../../assets/pricing-landing2.jpeg"

const { REACT_APP_REDIRECT_URI } = process.env;
const LandingPricing = () => {
    const navigate = useNavigate()
    return (
        <section >
            <div className="px-4 bg-white mb-8 py-8 rounded-3xl mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                <div className="flex flex-col items-center justify-between w-full mb-10 md:flex-row">
                    <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
                        <div className="sm:w-[750px] mb-6">
                            <h2 className="font-sans text-2xl sm:text-3xl sm:mt-0 mt-6 font-medium tracking-tight text-black sm:text-4xl sm:leading-none max-w-lg mb-6">
                                Basic - Free
                            </h2>
                            <p className="text-gray-600 text-xl sm:text-3xl">
                                For non-commercial use. Ideal for students and young professionals.
                            </p>
                            <ul className="list-disc pl-6 text-gray-600 text:md sm:text-2xl">
                                <li>Bail applications</li>
                                <li>Anticipatory bail applications</li>
                                <li>Unlimited on-screen generation of drafts</li>
                                <li>Draft Library to access past drafts</li>
                            </ul>
                        </div>
                        <div className='space-x-4'>
                            <button onClick={() => {
                                if (UserService.isLoggedIn()) navigate("/app")
                                else UserService.doSignUp({ redirectUri: REACT_APP_REDIRECT_URI + "/app" })
                            }}
                                style={{ fontSize: "1.5rem", zIndex: "999999999999" }}
                                className='btn btn-outline-dark hover:text-black px-5 mb-3 offset py-3'>
                                Register &#8594;
                            </button>
                        </div>
                    </div>
                    <img alt="pricing" width="420" height="120" src={pricingLanding1} />
                </div>
            </div>
            <div className="px-4 bg-white py-8 rounded-3xl mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
                <div className="flex flex-col items-center justify-between w-full mb- lg:flex-row">
                    <img alt="pricing" width="420" height="120" src={pricingLanding2} />
                    <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
                        <div className="max-w-xl mb-6">
                            <h2 className="font-sans text-2xl sm:text-3xl sm:mt-0 mt-6 font-medium tracking-tight text-black sm:text-4xl sm:leading-none max-w-lg mb-6">
                                Sign-up - Professional
                            </h2>
                            <p className="text-gray-600 text-xl sm:text-3xl">
                                INR 1,999 / year
                            </p>
                            <ul className="list-disc pl-6 text-gray-600 text:md sm:text-2xl">
                                <li>Best for professionals, applicants and commercial use.</li>
                                <li>Everything in Free</li>
                                <li>Download fully formatted applications.</li>
                                <li>Access to AI advocates community channel.</li>
                                <li>Email support.</li>
                            </ul>
                        </div>
                        <div className='space-x-4'>
                            <button onClick={() => {
                                if (UserService.isLoggedIn()) navigate("/app")
                                else UserService.doSignUp({ redirectUri: REACT_APP_REDIRECT_URI + "/app" })
                            }}
                                style={{ fontSize: "1.5rem", zIndex: "999999999999" }}
                                className='btn btn-outline-dark hover:text-black px-5 mb-3 offset py-3'>
                                Register &#8594;
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingPricing;
