import React, { useEffect } from 'react'
import "../../components/css/landingpage.css"
import { useDispatch, useSelector } from 'react-redux'
import { getNavbar } from '../../Redux/Action/action'
import { redirect, useNavigate } from 'react-router'
import UserService from '../../services/UserService'
import vdo from '../../assets/Demo1.mp4'
import navbarBackgroundLeft from "../../assets/navbarBackgroundLeft.png"
import navbarBackgroundRight from "../../assets/navbarBackgroundRight.png"
import "../css/landingpage.css"
import landingBackground from "../../assets/landing-background.png"
import landingGroupBackground from "../../assets/landing-group-background.png"
import landingGroupBackCover from "../../assets/landing-group-background-cover.png"
import works1 from "../../assets/works1.jpeg"
import works2 from "../../assets/works2.jpeg"
import works3 from "../../assets/works3.jpeg"
import works4 from "../../assets/works4.jpeg"
import LandingPricing from './LandingPricing'

const { REACT_APP_REDIRECT_URI } = process.env;
const LandingTest = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        // Select the navbar element by its className name
        const navbar = document.querySelector(".navbar-custom");

        if (navbar) {
            // Hide the navbar
            navbar.style.display = "none";
        }

        // Cleanup function to restore the navbar visibility
        return () => {
            if (navbar) {
                navbar.style.display = "";
            }
        };
    }, []);

    return (
        <section>
            <div className='btn-dark py text-center'>
                <img loading="lazy" className="custom-position-explor" style={{ position: "absolute", top: "1vh", right: "2vw", zIndex: "2", height: "8rem" }} src={navbarBackgroundLeft} alt="Navbar Background Left" />
                <img loading="lazy" style={{ position: "absolute", right: "45vw", zIndex: "2", height: "8rem" }} src={navbarBackgroundRight} alt="Navbar Background Right" />
                <h1 style={{ fontSize: "2rem" }} className=''>Draft a Bail Application</h1>
                <p className='text-lg'>Revolutionising legal drafting with AI</p>
            </div>
            <div className='container-fluid responsive-padding-landing-page'>
                <div className="row relative">
                    <img src={landingBackground} style={{ zIndex: '-111' }} alt="landing-background" className='absolute -right-11 -top-16 w-[1200px] h-[250px] sm:h-[500px] hidden sm:block' />
                    <img src={landingGroupBackground} style={{ zIndex: '-111' }} alt="landing-background" className='absolute right-0 -top-16 w-[900px] h-[250px] sm:h-[500px] hidden sm:block' />
                    <div className="col-lg-6 py-4 flex flex-col">
                        <h1 className='welcome'>Draft a Bail Application in 10 minutes using AI
                        </h1>
                        <p className='second-heading'>
                            Create high-quality and fully formatted Indian bail applications effortlessly.
                        </p>
                        <div className='mt-4'>
                            <button onClick={() => {
                                if (UserService.isLoggedIn()) navigate("/app")
                                else UserService.doSignUp({ redirectUri: REACT_APP_REDIRECT_URI + "/app" })
                            }}
                                style={{ fontSize: "1.5rem", zIndex: "999999999999" }}
                                className='btn btn-outline-dark hover:text-black px-5 mb-3 offset py-3'>
                                Register &#8594;
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="row h-[400px] sm:h-[700px]"
                    style={{
                        backgroundImage: `url(${landingGroupBackCover})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        // transform: "scaleX(1.2)", // Increases width by 20%
                    }}
                >
                    <h1 className="text-4xl font-bold text-center relative sm:top-14">See It in Action! A Quick Tour of Our App</h1>
                    <div className="flex justify-center items-center my-3">
                        <div className="relative bg-gray-800 rounded-lg shadow-lg w-[800px] h-[250px] sm:h-[500px] border border-gray-300">
                            {/* Top Bar */}
                            <div className="absolute top-0 left-0 w-full h-10 bg-gray-900 rounded-t-lg flex items-center px-4">
                                <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
                                <div className="w-3 h-3 bg-yellow-400 rounded-full mr-2"></div>
                                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                            </div>
                            {/* Video Section */}
                            <div className="absolute top-10 left-0 w-full h-[calc(100%-40px)] overflow-hidden rounded-b-lg" >
                                <video
                                    src={vdo}
                                    controls
                                    autoPlay
                                    loop
                                    muted
                                    className="w-full h-full object-cover"
                                >
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="min-h-screen flex items-center justify">
                    <div className="rounded-lg space-y-8">
                        {/* <h1 className="text-4xl font-bold text-center">How it Works</h1> */}
                        <div className="space-y-4 mt-5">
                            <div className='row mt-2'>
                                <div className='col-sm-7'>
                                    <div className="flex flex-row flex-nowrap items-center my-3">
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                        <span className="mx-2 p-4 text-base leading-none font-medium uppercase btn-dark text-white">
                                            <span className="font-semibold" style={{ fontSize: "1.4rem" }}>
                                                Provide your case facts
                                            </span>
                                        </span>
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                    </div>
                                    <p className="text-gray-600 px-4 text-[1.2rem] sm:text-[1.8rem]">
                                        You can type your case facts in simple language and let our AI engine do the rest.
                                        Reduce the need to write and review in detail, spend not more than 5 minutes in writing
                                        your case facts, and generate high-quality drafts in less than a minute.
                                    </p>
                                </div>
                                <div className='col-sm-5 sm:pl-2'>
                                    <img className='ml-5' src={works1} alt="work-1" style={{ width: "70%" }} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-5 pl-3 sm:pl-2'>
                                    <img className='ml-5' src={works2} alt="work-2" style={{ width: "70%" }} />
                                </div>
                                <div className='col-md-7'>
                                    <div className="flex flex-row flex-nowrap items-center my-3">
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                        <span className="mx-2 p-4 text-base leading-none font-medium uppercase btn-dark text-white">
                                            <span className="font-semibold" style={{ fontSize: "1.4rem" }}>
                                                Built for Indian courts and formats
                                            </span>
                                        </span>
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                    </div>
                                    <p className="text-gray-600 px-4 py-2 text-[1.2rem] sm:text-[1.9rem]">
                                        Drafts are built around how Indian lawyers and courts expect bail and anticipatory bail
                                        applications. Especially good for filing in High Courts and in forums where English language is used.
                                    </p>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-7'>
                                    <div className="flex flex-row flex-nowrap items-center my-3">
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                        <span className="mx-2 p-4 text-base leading-none font-medium uppercase btn-dark text-white">
                                            <span className="font-semibold" style={{ fontSize: "1.4rem" }}>
                                                Unlimited drafts
                                            </span>
                                        </span>
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                    </div>
                                    <p className="text-gray-600 px-4 py-4 text-[1.2rem] sm:text-[2rem]">
                                        Generate as many drafts as you want, and work on as many applications as you like.
                                        No limits on learning, experimenting, and iterating.
                                    </p>
                                </div>
                                <div className='col-md-5 pl-5 sm:pl-2'>
                                    <img src={works3} alt="work-3" style={{ width: "70%" }} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-5 pl-3 sm:pl-2'>
                                    <img className='ml-5' src={works4} alt="work-4" style={{ width: "70%" }} />
                                </div>
                                <div className='col-md-7'>
                                    <div className="flex flex-row flex-nowrap items-center my-3">
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                        <span className="mx-2 p-4 text-base leading-none font-medium uppercase btn-dark text-white">
                                            <span className="font-semibold" style={{ fontSize: "1.4rem" }}>
                                                Outstanding formatting
                                            </span>
                                        </span>
                                        <span className="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                    </div>
                                    <p className="text-gray-600 px-5 text-[1.2rem] sm:text-[1.9rem]">
                                        Once satisfied with the substantive aspects of the draft, download a fully formatted
                                        application in Microsoft Word Docx format. Continue to edit and work on your own, or regenerate and download again!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <LandingPricing />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm card-column">
                        <div className="card-details">
                            <div className="card-icons">
                                <img width="48" height="48" src="https://img.icons8.com/parakeet-line/48/add-user-male.png" alt="add-user-male" />                                </div>
                            <h3>
                                <button onClick={() => UserService.doSignUp({ redirectUri: `${REACT_APP_REDIRECT_URI}/app` })}
                                    style={{ fontSize: "1.5rem" }}
                                    className='btn btn-outline-dark px-5'>
                                    Register
                                </button>
                            </h3>
                            <p className='w-full'>Register now to start drafting a bail application using AI.</p>
                        </div>
                    </div>
                    <div className="col-auto flex justify-center items-center">
                        {/* Vertical Line */}
                        <div className="border-l-4 border-gray-400 h-full"></div>
                    </div>
                    <div className="col-sm card-column">
                        <div className="card-details">
                            <div className="card-icons">
                                <img width="48" height="48" src="https://img.icons8.com/badges/48/enter-2.png" alt="enter-2" />                                                                    </div>
                            <h3>
                                <button onClick={() => {
                                    if (UserService.isLoggedIn()) navigate("/app")
                                    else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app" })
                                }}
                                    style={{ fontSize: "1.5rem" }}
                                    className='btn btn-outline-dark px-5'>
                                    Sign In
                                </button>
                            </h3>
                            <p>Proceed to your account to start drafting now.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default LandingTest