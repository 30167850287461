import React from 'react'
import { Link } from 'react-router-dom';
import backgroundImageNav from "../../assets/backgroundImageNav.png"
import backgroundImageNav2 from "../../assets/backgroundImageNav2.png"
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'
import logo from "../../assets/DBA_LOGO.png"

const FooterLanding = () => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <footer className='relative btn-dark w-full flex flex-col sm:flex-row items-center justify-center' style={{ padding: "3vw 8vw 2vw 8vw", backgroundImage: `url(${backgroundImageNav})`, backgroundRepeat: "no-repeat", }}>
            <img className='w-[15vw] sm:w-[5vw] mx-4' src={logo} alt="logo" />
            <span className='sm:-ml-5 mr-5 text-white text-[0.7rem] sm:text-[1.3rem]' style={{ opacity: "0.5" }}>2024 @ Lipi Labs Private Limited. All Rights Reserved.</span>
        </footer>
    )
}

export default FooterLanding
{/* <footer
className="relative w-full flex flex-col sm:flex-row items-center justify-center text-center p-6 bg-cover bg-center"
style={{
  backgroundImage: `url(${backgroundImageNav})`,
}}
>
<div className="absolute inset-0 bg-black opacity-40"></div>

<div className="relative flex flex-col sm:flex-row items-center gap-4">
  <img src={logo} alt="logo" className="w-[5vw] sm:w-[3vw]" />
  <span className="text-white text-[0.8rem] sm:text-[1.2rem] opacity-80">
    2024 @ Lipi Labs Private Limited. All Rights Reserved.
  </span>
</div>
</footer> */}